import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_CREATE_EVENT_APPROVAL_AMOUNT_CONFIG_API,
  RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_ALL_API,
  RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_FIND_BY_ID_API,
  RQ_UPDATE_EVENT_APPROVAL_AMOUNT_CONFIG_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateEventApprovalAmountConfig = async ({
  eventApprovalAmountConfig,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EVENT_APPROVAL_AMOUNT_CONFIG_API,
    JSON.stringify({...eventApprovalAmountConfig}),
    token,
    null,
    signal,
  );
};

export const RQGetAllEventApprovalAmountConfig = payload => {
  return useQuery(
    ['rq_get_all_event_approval_amount_config', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_ALL_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
  );
};

export const RQPutEventApprovalAmountConfigUpdateAllField = async ({
  eventApprovalAmountConfig,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EVENT_APPROVAL_AMOUNT_CONFIG_API,
    JSON.stringify({...eventApprovalAmountConfig}),
    token,
    null,
    signal,
  );
};

export const RQGetEventApprovalAmountConfigByIdData = payload => {
  return useQuery(
    ['rq_get_event_approval_amount_config_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_FIND_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
