import PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import {Table} from 'antd';
import React from 'react';

const SearchTable = ({data, columns, dataSource}) => {

  let displayedColumns = columns.map(column => {
    column['onCell']=  () => {
      return {
        style: {minWidth: 100, maxWidth: 400},
      };
    };
    return column;
  });

  return (
    <Table
      columns={displayedColumns}
      dataSource={dataSource}
      rowKey={'id'}
      size={'large'}
      bordered
      defaultPageSize={1}
      pagination={{defaultPageSize: 25}}
      loading={!data}
      scroll={{
        x: 'max-content',
        y: '80vh',
      }}
    />
  );
};

SearchTable.propTypes = {
  data: PropTypes.array,
  dataSource: PropTypes.any,
  columns: PropTypes.any,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SearchTable);
