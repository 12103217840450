export const RQ_CREATE_KOL_SCOPE_EVENT_API = {
  url: 'v2/kol-event/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_TYPE_API = {
  url: 'v2/event-definition/find-kol-events/<%= eventName %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_KOL_EVENT_SEARCH_API = {
  url: 'v2/kol-event/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_KOL_EVENT_BY_ID_API = {
  url: 'v2/kol-event/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_APPROVE_KOL_EVENT_API = {
  url: 'v2/kol-event/approve-kol-event',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_KOL_EVENT_API = {
  url: 'v2/kol-event/reject-kol-event',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_KOL_SCOPE_EVENT_API = {
  url: 'v2/kol-event/resubmit',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_KOL_WALLET_BALANCE_API = {
  url: 'v2/wallet-balance/balance-for-brands/<%= eventDate %>/<%= eventDefinitionId %>/<%= locationId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_KOL_ADVANCE_API = {
  url: 'v2/kol-event/updateAdvancePayments/<%= eventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_KOL_SCOPES_BY_EVENT_ID={
  url: 'v2/kol-scope-event/findScopeforKOLEvent/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};
