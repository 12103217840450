import {createBrowserRouter} from 'react-router-dom';
import {ErrorPage} from '../components/common/ErrorPage';
import React from 'react';
import {PublicRoute} from './PublicRoute';
import {LoginNavigation} from './screensNavigation/Login';
import {ROUTE_BASE_NAME} from './RoutePathConstant';
import {MainHome} from '../screensComponents/HomeScreen/MainHome';
import {IndexNavigation} from './screensNavigation/Index';
import {BrandNavigation} from './screensNavigation/BrandNavigation';
import {DashboardIndexNavigation} from './screensNavigation/DashboardIndex';
import {JobRoleNavigation} from './screensNavigation/JobRoleNavigation';
import {BudgetTypeNavigation} from './screensNavigation/BudgetTypeNavigation';
import {DivisionNavigation} from './screensNavigation/DivisionNavigation';
import {FreezeAllocationNavigation} from './screensNavigation/FreezeAllocationNavigation';
import {DocumentsNavigation} from './screensNavigation/DocumentsNavigation';
import {MyTeamNavigation} from './screensNavigation/MyTeamNavigation';
import {MyBudgetNavigation} from './screensNavigation/MyBudgetNavigation';
import {NewRequestNavigation} from './screensNavigation/NewRequestNavigation';
import {SearchHcpNavigation} from './screensNavigation/SearchHcpNavigation';
import {BudgetUploadNavigation} from './screensNavigation/BudgetUploadNavigation';
import {EventConfigurationNavigation} from './screensNavigation/EventConfigurationNavigation';
import {ApprovalChainDefinitionNavigation} from './screensNavigation/ApprovalChainDefinitionNavigation';
import {CreateEventNavigation} from './screensNavigation/CreateEventNavigation';
import {ViewRequestNavigation} from './screensNavigation/ViewRequestNavigation';
import {CloseEventNavigation} from './screensNavigation/CloseEventNavigation';
import {LocationNavigation} from './screensNavigation/LocationNavigation';
import {UserNavigation} from './screensNavigation/UserNavigation';
import {VendorConferenceEventNavigation} from './screensNavigation/VendorConferenceEventNavigation';
import SecurityRoleNavigation from './screensNavigation/SecurityRoleNavigation/SecurityRoleNavigation';
import {SearchVendorNavigation} from './screensNavigation/SearchVendorNavigation';
import {DoctorUploadNavigation} from './screensNavigation/DoctorUploadNavigation';
import {ReportDownloadNavigation} from './screensNavigation/ReportDownloadNavigation';
import {BackendUpdateNavigation} from './screensNavigation/BackendUpdateNavigation';
import {PassbookNavigation} from './screensNavigation/PassbookNavigation';
import {PaymentUploadNavigation} from './screensNavigation/PaymentUploadNavigation';
import {PullBackRequestNavigation} from './screensNavigation/PullBackRequestNavigation';
import {ConfigurationMasterNavigation} from './screensNavigation/ConfigurationMasterNavigation';
import {CreateScopeNavigation} from './screensNavigation/CreateScopeNavigation';
import {TemplateMasterNavigation} from './screensNavigation/TemplateMasterNavigation';
import {CancellationReasonNavigation} from './screensNavigation/CancellationReasonNavigation';
import {KolEventNavigation} from './screensNavigation/KolEventNavigation';
import {EventApprovalAmountConfigNavigation} from './screensNavigation/EventApprovalAmountConfigNavigation';

const AuthRoute = [
  ...DivisionNavigation,
  ...BrandNavigation,
  ...JobRoleNavigation,
  ...BudgetTypeNavigation,
  ...SearchHcpNavigation,
  ...FreezeAllocationNavigation,
  ...DocumentsNavigation,
  ...MyTeamNavigation,
  ...MyBudgetNavigation,
  ...NewRequestNavigation,
  ...BudgetUploadNavigation,
  ...EventConfigurationNavigation,
  ...ApprovalChainDefinitionNavigation,
  ...CreateEventNavigation,
  ...ViewRequestNavigation,
  ...CloseEventNavigation,
  ...LocationNavigation,
  ...UserNavigation,
  ...VendorConferenceEventNavigation,
  ...SecurityRoleNavigation,
  ...SearchVendorNavigation,
  ...DoctorUploadNavigation,
  ...ReportDownloadNavigation,
  ...BackendUpdateNavigation,
  ...PassbookNavigation,
  ...PaymentUploadNavigation,
  ...PullBackRequestNavigation,
  ...ConfigurationMasterNavigation,
  ...CreateScopeNavigation,
  ...TemplateMasterNavigation,
  ...CancellationReasonNavigation,
  ...KolEventNavigation,
  ...EventApprovalAmountConfigNavigation,
];

export const AppRouter = createBrowserRouter([
  IndexNavigation,
  LoginNavigation,
  {
    path: '',
    element: <MainHome />,
    errorElement: <ErrorPage />,
    children: [
      DashboardIndexNavigation,
      ...AuthRoute,
      ...PublicRoute,
    ],
  },
], {
  basename: ROUTE_BASE_NAME,
});
