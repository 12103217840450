import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_HCP, ROUTE_EDIT_HCP, ROUTE_EDIT_HCP_REGISTRATION, ROUTE_SEARCH_HCP, ROUTE_VIEW_HCP, ROUTE_VIEW_HCP_WITH_TAB_PARAM} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const SearchHCP = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.SearchHCP})));

const AddHcp = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.AddHcp})));

const EditHcp = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.EditHCP})));

const ViewHcp = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.ViewHcp})));

const ViewHcpDetails = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.ViewHcpDetails})));

const EditHcpDetails = lazy(() =>
  import('../../../screens/SearchHCP')
    .then(module => ({default: module.EditHcpDetails})));

const SearchHcpNavigation = [
  {
    path: ROUTE_SEARCH_HCP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <SearchHCP />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_HCP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddHcp />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_HCP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditHcp />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_HCP,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewHcp />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_HCP_WITH_TAB_PARAM,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewHcpDetails />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_HCP_REGISTRATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditHcpDetails />
      </Suspense>
    ),
  },
];

export default SearchHcpNavigation;
