import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_ADD_CANCELLATION_REASON, ROUTE_CANCELLATION_REASON, ROUTE_EDIT_CANCELLATION_REASON} from '../../RoutePathConstant';

const CancellationReason = lazy(() =>
  import('../../../screens/CancellationReason')
    .then(module => ({default: module.CancellationReason})));

const EditCancellationReason = lazy(() =>
  import('../../../screens/CancellationReason/')
    .then(module => ({default: module.EditCancellationReason})));

const AddCancellationReason = lazy(() =>
  import('../../../screens/CancellationReason/')
    .then(module => ({default: module.AddCancellationReason})));

const CancellationReasonNavigation = [
  {
    path: ROUTE_CANCELLATION_REASON,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CancellationReason />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_CANCELLATION_REASON,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditCancellationReason />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_CANCELLATION_REASON,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddCancellationReason />
      </Suspense>
    ),
  },
];

export default CancellationReasonNavigation;
