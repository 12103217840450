import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_APPROVE_HCP_API,
  RQ_GET_BANK_DETAIL_BY_ID_API,
  RQ_GET_BANK_DETAIL_BY_STATUS_API,
  RQ_GET_BANK_HISTORY_API,
  RQ_GET_DOCTOR_LIST_BY_NAME_API,
  RQ_GET_DOCUMENT_HISTORY_API,
  RQ_GET_FMV_DETAIL_BY_ID_API,
  RQ_GET_FMV_DETAIL_BY_STATUS_API,
  RQ_GET_FMV_HISTORY_API,
  RQ_GET_FMV_MASTER_API,
  RQ_GET_FMV_NEW_HISTORY_API,
  RQ_GET_HCP_HISTORY_API,
  RQ_GET_HCP_PROFILE_API,
  RQ_GET_REGISTERED_DOCTOR_BY_ID_API,
  RQ_GET_REGISTER_DOCTOR_LIST_BY_NAME_API,
  RQ_GET_SEARCH_HCP_REGISTER_API,
  RQ_HCP_REGISTER_ALL_FIELDS_API,
  RQ_REJECT_HCP_API, RQ_RESUBMIT_HCP_API, RQ_UPDATE_DOCUMENT_DETAILS_API, RQ_UPDATE_FMV_DETAILS_API,
} from './constants';

export const RQUseGetDoctorListByNameData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_doctor_list_by_name_data', payload],
    async ({queryKey, signal}) => {
      const {filter,  token} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_DOCTOR_LIST_BY_NAME_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPostHcpRegisterAllFields = async ({
  profileInformation,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_HCP_REGISTER_ALL_FIELDS_API,
    JSON.stringify({...profileInformation}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchHcpData = payload => {
  return useQuery(
    ['rq_get_search_hcp_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SEARCH_HCP_REGISTER_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQGetHcpProfileData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_hcp_profile_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HCP_PROFILE_API,
        null,
        token,
        {id: id},
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQGetSearchFMVMasterData = payload => {
  return useQuery(
    ['rq_get_search_fmv_master_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FMV_MASTER_API,
        null,
        token,
        null,
        signal,
        filter );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetRegisteredDoctorByIdData = payload => {
  return useQuery(
    ['rq_get_registered_doctor_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {token, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_REGISTERED_DOCTOR_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQHCPApprove = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_HCP_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQHCPResubmit = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_HCP_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQHCPReject = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REJECT_HCP_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchFMVDetailForDoctor = (payload, options = {}) => {
  return useQuery(
    ['rq_get_search_fmv_detail_data', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FMV_DETAIL_BY_ID_API,
        null,
        token,
        params,
        signal);
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQGetSearchBankDetailForDoctor = (payload, options={}) => {
  return useQuery(
    ['rq_get_search_bank_detail_data', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BANK_DETAIL_BY_ID_API,
        null,
        token,
        params,
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetRegisterDoctorListByNameData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_register_doctor_list_by_name_data', payload],
    async ({queryKey, signal}) => {
      const {filter,  token} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_REGISTER_DOCTOR_LIST_BY_NAME_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQGetFMVHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_fmv_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FMV_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};

export const RQGetBankHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_bank_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BANK_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};
export const RQGetDocumentHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_document_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_DOCUMENT_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};
export const RQUpdateFMVDetails = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_FMV_DETAILS_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQUpdateDocumentDetails = async ({
  documents,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_DOCUMENT_DETAILS_API,
    JSON.stringify({...documents}),
    token,
    null,
    signal,
  );
};

export const RQGetFMVNewHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_new_fmv_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, id} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FMV_NEW_HISTORY_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
  );
};

export const RQGetHCPHistory = payload => {
  return useQuery(
    ['rq_get_hcp_history', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_HCP_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};

export const RQGetSearchFMVDetailByStatusForDoctor = (payload, options = {}) => {
  return useQuery(
    ['rq_get_search_fmv_detail_data_by_status', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_FMV_DETAIL_BY_STATUS_API,
        null,
        token,
        params,
        signal);
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQGetSearchBankDetailByStatusForDoctor = (payload, options={}) => {
  return useQuery(
    ['rq_get_search_bank_detail_data_by_status', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BANK_DETAIL_BY_STATUS_API,
        null,
        token,
        params,
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
