export const RQ_CREATE_TEMPLATE_API = {
  url: 'v2/template-master/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_ALL_TEMPLATES_API = {
  url: 'v2/template-master/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_TEMPLATE_FIND_BY_ID_API = {
  url: 'v2/template-master/findById/<%= tempId %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_UPDATE_TEMPLATE_API = {
  url: 'v2/template-master/update',
  method: 'PUT',
  auth: true,
};
