import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {ROUTE_ADD_SCOPE_CREATION, ROUTE_APPROVE_REJECT_SCOPE_CREATION, ROUTE_CREATE_SCOPE, ROUTE_EDIT_SCOPE_CREATION, ROUTE_RESUBMIT_SCOPE_CREATION, ROUTE_VIEW_SCOPE_CREATION} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const CreateScope = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.CreateScope})));

const CreateNewScope = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.AddScope})));

const EditNewScope = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.ResubmitScope})));

const ApproveRejectScopeCreation = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.ApproveRejectScopeCreation})));

const ResubmitScopeCreation = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.ResubmitScopeCreation})));

const ViewNewScope = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.ViewScope})));

const EditScopes = lazy(() =>
  import('../../../screens/CreateScope')
    .then(module => ({default: module.EditScope})));

const CreateScopeNavigation = [
  {
    path: ROUTE_CREATE_SCOPE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateScope />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateNewScope />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewNewScope />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditScopes />
      </Suspense>
    ),
  },

  {
    path: ROUTE_RESUBMIT_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditNewScope />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectScopeCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_RESUBMIT_SCOPE_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ResubmitScopeCreation />
      </Suspense>
    ),
  },

];

export default CreateScopeNavigation;
