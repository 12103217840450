export const RQ_CREATE_CANCELLATION_REASON_API = {
  url: 'v2/cancellationReason/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_CANCELLATION_REASON_ALL_API = {
  url: 'v2/cancellationReason/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_UPDATE_CANCELLATION_REASON_API = {
  url: 'v2/cancellationReason/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_CANCELLATION_REASON_FIND_BY_ID_API = {
  url: 'v2/cancellationReason/findById/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};
