export const RQ_CREATE_EVENT_APPROVAL_AMOUNT_CONFIG_API = {
  url: 'v2/eventApproval_amount_config/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_ALL_API = {
  url: 'v2/eventApproval_amount_config/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_UPDATE_EVENT_APPROVAL_AMOUNT_CONFIG_API = {
  url: 'v2/eventApproval_amount_config/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EVENT_APPROVAL_AMOUNT_CONFIG_FIND_BY_ID_API = {
  url: 'v2/eventApproval_amount_config/findById/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};
