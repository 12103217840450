
export const RQ_GET_DOCUMENT_BY_OWNER_LIST_API = {
  url: 'v2/documents/by-owner/<%= ownerId %>',
  method: 'GET',
  auth: true,
};

export const RQ_GET_DOCUMENT_BY_OWNER_LIST_BY_STATUS_API = {
  url: 'v2/documents/by-owner-by-status/<%= ownerId %>',
  method: 'GET',
  auth: true,
};
