import {Col, Typography} from 'antd';
import React from 'react';
import './WelcomeHeader.less';
import PropTypes from 'prop-types';
import {selectAuth} from '../../../../../redux/selectors';
import {connect} from 'react-redux';

const WelcomeHeader = ({auth}) => {
  console.log(auth);
  return (
    <Col className={'welcome-header-box'}>
      <Typography className={'welcome-admin-title'}>
          Welcome, {auth?.user?.fullName} ({auth?.user?.jobRole?.name})
      </Typography>
    </Col>
  );
};

WelcomeHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(WelcomeHeader);
