import React, {Suspense, lazy} from 'react';
import {ROUTE_APPROVE_REJECT_KOL_CREATION, ROUTE_CREATE_SCOPE_EVENT, ROUTE_KOL, ROUTE_RESUBMIT_KOL_CREATION, ROUTE_UPDATE_ADVANCED_REQUEST, ROUTE_VIEW_KOL_EVENT, ROUTE_VIEW_KOL_EVENT_SCOPES} from '../../RoutePathConstant';
import {Spin} from 'antd';
import {authLoader} from '../../loader';

const Kol = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.KolEvents})));

const ScopeEventCreation = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.CreateNewScopeEvent})));

const ViewEvents = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.ViewEvent})));

const ApproveRejectKolEvent = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.ApproveRejectScopeEvent})));

const Resubmit = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.ResubmitKolEvents})));

const ApproveRejectKolEventByFinance = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.ApproveRejectKolEventByFinance})));

const ViewEventScopes = lazy(() =>
  import('../../../screens/KolEvents')
    .then(module => ({default: module.ViewEventScopes})));

const KolEventNavigation = [
  {
    path: ROUTE_KOL,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Kol />
      </Suspense>
    ),
  },
  {
    path: ROUTE_CREATE_SCOPE_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ScopeEventCreation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_KOL_EVENT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewEvents />
      </Suspense>
    ),
  },
  {
    path: ROUTE_APPROVE_REJECT_KOL_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectKolEvent />
      </Suspense>
    ),
  },
  {
    path: ROUTE_RESUBMIT_KOL_CREATION,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <Resubmit />
      </Suspense>
    ),
  },
  {
    path: ROUTE_UPDATE_ADVANCED_REQUEST,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ApproveRejectKolEventByFinance />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_KOL_EVENT_SCOPES,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewEventScopes />
      </Suspense>
    ),
  },
];
export default KolEventNavigation;
