import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_APPROVE_KOL_EVENT_API, RQ_CREATE_KOL_SCOPE_EVENT_API, RQ_GET_EVENT_TYPE_API, RQ_GET_KOL_EVENT_BY_ID_API, RQ_GET_KOL_EVENT_SEARCH_API, RQ_GET_KOL_SCOPES_BY_EVENT_ID, RQ_GET_KOL_WALLET_BALANCE_API, RQ_REJECT_KOL_EVENT_API, RQ_RESUBMIT_KOL_SCOPE_EVENT_API, RQ_UPDATE_KOL_ADVANCE_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQUseGetEventType = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_type', payload],
    async ({queryKey, signal}) => {
      const {eventName, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_TYPE_API,
        null,
        token,
        {eventName:eventName},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPostCreateKolScopeEvent = async ({
  kolEvent,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_KOL_SCOPE_EVENT_API,
    JSON.stringify({...kolEvent}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchKolEventData = payload => {
  return useQuery(
    ['rq_get_search_kol_event_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_EVENT_SEARCH_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetKolEventByIdData = payload => {
  return useQuery(
    ['rq_get_kol_event_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_KOL_EVENT_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutApproveKolEvent = async ({
  approve,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_KOL_EVENT_API,
    JSON.stringify({...approve}),
    token,
    null,
    signal,
  );
};

export const RQPutRejectKolEvent = async ({
  reject,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REJECT_KOL_EVENT_API,
    JSON.stringify({...reject}),
    token,
    null,
    signal,
  );
};

export const RQPutResubmitKolEvent = async ({
  resubmit,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_KOL_SCOPE_EVENT_API,
    JSON.stringify({...resubmit}),
    token,
    null,
    signal,
  );
};

export const RQUseGetWalletBalanceForKolEvent = payload => {
  return useQuery(
    ['rq_get_wallet_balance_kol_event', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_WALLET_BALANCE_API,
        null,
        token,
        params,
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUpdateKolPaymentInfo = async ({
  eventId,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_KOL_ADVANCE_API,
    JSON.stringify({...details}),
    token,
    {eventId : eventId},
    signal,
  );
};

export const RQGetKolScopesByEventId = payload => {
  return useQuery(
    ['rq_get_kol_scopes_by_event_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_KOL_SCOPES_BY_EVENT_ID,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
