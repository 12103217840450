export const RQ_CREATE_EVENT_CONFIGURATION_API = {
  url: 'v2/event-definition/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_CONFIGURATION_LIST_API = {
  url: 'v2/event-definition/all',
  method: 'GET',
  auth: true,
};

export const RQ_GET_EVENT_CONFIGURATION_BY_ID_API = {
  url: 'v2/event-definition/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_EVENT_REPORT_BY_EVENT_ID_API = {
  url: 'v2/event-report/for-event/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_WALLET_BALANCE_BY_EVENT_API = {
  url: 'v2/wallet-balance/balance/<%= eventDate %>/<%= eventDefinitionId %>/<%= locationId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_WALLET_BALANCE_FOR_EVENT_API = {
  url: 'v2/wallet-balance/balance-for-event/<%= eventId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_EVENT_CONFIGURATION_ALL_FIELDS_API = {
  url: 'v2/event-definition/update',
  method: 'PUT',
  auth: true,
};

export const RQ_REPORT_EVENT_CLOSURE = {
  url: 'v2/event-report/report',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SEARCH_EVENT_CONFIGURATION_API = {
  url: 'v2/event-definition/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_EVENT_CONFIGURATION_BY_EVENT_NAME_API = {
  url: 'v2/event-definition/findByEventName/<%= eventName %>?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_EVENT_DEFINITION_FIND_BY_BRAND_API = {
  url: 'v2/event-definition/findEventsBy/<%= brandId %>?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_APPROVE_EVENT_DEFINITION = {
  url: 'v2/event-definition/approve-event-definition',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_EVENT_DEFINITION = {
  url: 'v2/event-definition/reject-event-definition',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_EVENT_DEFINITION = {
  url: 'v2/event-definition/resubmit',
  method: 'PUT',
  auth: true,
};
