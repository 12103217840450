import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {authLoader} from '../../loader';
import {ROUTE_ADD_EVENT_APPROVAL_AMOUNT_CONFIG, ROUTE_EDIT_EVENT_APPROVAL_AMOUNT_CONFIG, ROUTE_EVENT_APPROVAL_AMOUNT_CONFIG} from '../../RoutePathConstant';

const EventApprovalAmountConfig = lazy(() =>
  import('../../../screens/EventApprovalAmountConfig')
    .then(module => ({default: module.EventApprovalAmountConfig})));

const AddEventApprovalAmountConfig = lazy(() =>
  import('../../../screensComponents/EventApprovalAmountConfig/AddEventApprovalAmountConfig')
    .then(module => ({default: module.AddEventApprovalAmountConfig})));

const EditEventApprovalAmountConfig = lazy(() =>
  import('../../../screensComponents/EventApprovalAmountConfig/EditEventApprovalAmountConfig')
    .then(module => ({default: module.EditEventApprovalAmountConfig})));

const EventApprovalAmountConfigNavigation = [
  {
    path: ROUTE_EVENT_APPROVAL_AMOUNT_CONFIG,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EventApprovalAmountConfig />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_EVENT_APPROVAL_AMOUNT_CONFIG,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditEventApprovalAmountConfig />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_EVENT_APPROVAL_AMOUNT_CONFIG,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddEventApprovalAmountConfig />
      </Suspense>
    ),
  },
];

export default EventApprovalAmountConfigNavigation;
