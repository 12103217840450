import React, {Suspense, lazy} from 'react';
import {ROUTE_ADD_TEMPLATE, ROUTE_EDIT_TEMPLATE, ROUTE_TEMPLATE_MASTER, ROUTE_VIEW_TEMPLATE} from '../../RoutePathConstant';
import {Spin} from 'antd';
import {authLoader} from '../../loader';

const TemplateMaster = lazy(() =>
  import('../../../screens/TemplateMaster')
    .then(module => ({default: module.TemplateMaster})));

const ViewTemplateMaster = lazy(() =>
  import('../../../screens/TemplateMaster')
    .then(module => ({default: module.ViewTemplateMaster})));

const AddTemplateMaster = lazy(() =>
  import('../../../screens/TemplateMaster')
    .then(module => ({default: module.AddTemplateMaster})));

const EditTemplateMaster = lazy(() =>
  import('../../../screens/TemplateMaster')
    .then(module => ({default: module.EditTemplateMaster})));

const TemplateMasterNavigation = [
  {
    path: ROUTE_TEMPLATE_MASTER,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <TemplateMaster />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_TEMPLATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddTemplateMaster />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_TEMPLATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewTemplateMaster />
      </Suspense>
    ),
  },
  {
    path: ROUTE_EDIT_TEMPLATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditTemplateMaster />
      </Suspense>
    ),
  },
];

export default TemplateMasterNavigation;
