import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_CREATE_TEMPLATE_API, RQ_GET_ALL_TEMPLATES_API, RQ_GET_TEMPLATE_FIND_BY_ID_API, RQ_UPDATE_TEMPLATE_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateTemplate = async ({
  template,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_TEMPLATE_API,
    JSON.stringify({...template}),
    token,
    null,
    signal,
  );
};

export const RQGetAllTemplates = payload => {
  return useQuery(
    ['rq_get_all_templates', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_ALL_TEMPLATES_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
  );
};

export const RQGetTemplateByIdData = payload => {
  return useQuery(
    ['rq_get_template_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_TEMPLATE_FIND_BY_ID_API,
        null,
        token,
        {tempId:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutTemplateUpdateAlField = async ({
  template,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_TEMPLATE_API,
    JSON.stringify({...template}),
    token,
    null,
    signal,
  );
};
