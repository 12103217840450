import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_CREATE_CANCELLATION_REASON_API, RQ_GET_CANCELLATION_REASON_ALL_API, RQ_GET_CANCELLATION_REASON_FIND_BY_ID_API, RQ_UPDATE_CANCELLATION_REASON_API} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateCancellationReason = async ({
  reason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_CANCELLATION_REASON_API,
    JSON.stringify({...reason}),
    token,
    null,
    signal,
  );
};

export const RQGetAllCancellationReason = payload => {
  return useQuery(
    ['rq_get_all_cancellation_reasons', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_CANCELLATION_REASON_ALL_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
  );
};

export const RQPutCancellationReasonUpdateAlField = async ({
  reason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_CANCELLATION_REASON_API,
    JSON.stringify({...reason}),
    token,
    null,
    signal,
  );
};

export const RQGetCancellationReasonByIdData = payload => {
  return useQuery(
    ['rq_get_cancellation_reason_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_CANCELLATION_REASON_FIND_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};
